export const Legal = () => (
  <div className="container">
    <h2 className="text-center mt-5 mb-5">Mentions légales</h2>
    <span className="fw-bold">Nom de l’association : Basket club Mirepoix</span>
    <br />
    <br />
    <span className="fw-bold">Adresse :</span>
    <br />
    Mairie de Mirepoix
    <br />
    Place du Marechal LECLERC
    <br />
    09500 Mirepoix
    <br />
    <br />
    <span className="fw-bold">Numéro de téléphone : 06 61 20 57 63</span>
    <br />
    <br />
    <span className="fw-bold">
      Directeur de la publication (nom du président de l’association) :{" "}
    </span>
    M. BRIMAUD Denis
    <br />
    <br />
    <span className="fw-bold">Hébergeur :</span>
    <br />
    <br />
    <span>1&1 IONOS SARL</span>
    <br />
    <span>Téléphone (Service client) : 09 70 808 911</span>
    <br />
    <span>
      Adresse de courrier électronique (Service client) : info@ionos.fr
    </span>
    <br />
    <span>Adresse : 7 place de la Gare</span>
    <br />
    <span>BP 70109</span>
    <br />
    <span>57200 Sarreguemines CEDEX</span>
    <br />
    <br />
    <button
      className="btn btn-primary"
      onClick={() =>
        window.open("/Journal officiel.pdf", "_blank", "noreferrer")
      }
    >
      Consulter la parution dans le journal officiel
    </button>
  </div>
);

export default Legal;
