import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Form, Button } from "react-bootstrap";
import { TelephoneForwardFill, EnvelopeAtFill } from "@styled-icons/bootstrap";

const Contact = () => {
  const [formData, setFormData] = useState({
    lastName: "",
    firstName: "",
    email: "",
    phone: "",
    message: "",
  });
  const [formResponse, setFormResponse] = useState({
    message: "",
    responseOk: true,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Traiter les données du formulaire ici (envoyer au serveur, etc.)
    try {
      const response = await fetch(
        `${process.env.REACT_APP_STMP_SERVER_BASE_URL}/send-email`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            to: process.env.REACT_APP_STMP_SERVER_MAILTO,
            subject: `Basket Club Mirepoix : Demande d'informations de ${formData.lastName} ${formData.firstName}`,
            text: `Bonjour Denis,
		  
${formData.lastName} ${formData.firstName} a fait une demande d'informations.

Mail : ${formData.email}
Téléphone : ${formData.phone}
Message : ${formData.message}`,
          }),
        },
      );

      if (response.ok) {
        setFormResponse({ message: "Message envoyé !", responseOk: true });
        setFormData({
          lastName: "",
          firstName: "",
          email: "",
          phone: "",
          message: "",
        });
      } else {
        setFormResponse({
          message: "Il y a eu un problème dans l'envoie du message !",
          responseOk: false,
        });
      }
    } catch (error) {
      setFormResponse({
        message: "Error sending email: " + error,
        responseOk: false,
      });
    }

    setTimeout(() => {
      setFormResponse({ message: "", responseOk: false });
    }, 5000);
  };

  return (
    <section className="mb-5 mt-5" id="contact">
      <Container>
        <Row>
          <Col xs={12}>
            <h1
              className="text-center"
              style={{
                letterSpacing: "-.05rem",
                marginBottom: "4rem",
                fontSize: "50px",
              }}
            >
              Nous contacter
            </h1>
          </Col>
          <Col xs={12} md={4} xl={3} style={{ marginBottom: "2rem" }}>
            <h5
              className="text-center"
              style={{ letterSpacing: "-.05rem", marginBottom: "2rem" }}
            >
              En direct
            </h5>
            <TelephoneForwardFill
              className="mb-2"
              style={{ height: "20px", color: "#155263" }}
            />
            <span>
              {" "}
              <a href="tel:+33661205763">06 61 20 57 63</a>
            </span>
            <br />
            <EnvelopeAtFill
              className="mb-2"
              style={{ height: "20px", color: "#155263" }}
            />
            <span>
              {" "}
              <a href={`mailto:${process.env.REACT_APP_STMP_SERVER_MAILTO}`}>
                {process.env.REACT_APP_STMP_SERVER_MAILTO}
              </a>
            </span>
            <br />
            {/* <HouseDoorFill className="mb-2" style={{ height: "20px", color: "#155263" }}/><span> 06 61 20 57 63</span> */}
          </Col>
          <Col xs={12} md={8} xl={9} className="m-auto">
            <h5
              className="text-center"
              style={{ letterSpacing: "-.05rem", marginBottom: "2rem" }}
            >
              Par formulaire
            </h5>

            <Form onSubmit={handleSubmit}>
              <Row className="g-2">
                <Col md>
                  <Form.FloatingLabel controlId="lastName" label="Nom">
                    <Form.Control
                      type="text"
                      name="lastName"
                      placeholder="Nom"
                      value={formData.lastName}
                      onChange={handleChange}
                      required
                    />
                  </Form.FloatingLabel>
                </Col>

                <Col md>
                  <Form.FloatingLabel controlId="firstName" label="Prénom">
                    <Form.Control
                      type="text"
                      name="firstName"
                      placeholder="Prénom"
                      value={formData.firstName}
                      onChange={handleChange}
                      required
                    />
                  </Form.FloatingLabel>
                </Col>
              </Row>

              <Row className="g-2 mt-2">
                <Col md>
                  <Form.FloatingLabel controlId="email" label="Email">
                    <Form.Control
                      type="email"
                      name="email"
                      placeholder="Email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </Form.FloatingLabel>
                </Col>

                <Col md>
                  <Form.FloatingLabel controlId="phone" label="Téléphone">
                    <Form.Control
                      type="tel"
                      name="phone"
                      placeholder="Téléphone"
                      value={formData.phone}
                      onChange={handleChange}
                      required
                    />
                  </Form.FloatingLabel>
                </Col>
              </Row>

              <Row className="g-2 mt-2">
                <Col md>
                  <Form.FloatingLabel controlId="message" label="Message">
                    <Form.Control
                      as="textarea"
                      name="message"
                      placeholder="Message"
                      value={formData.message}
                      onChange={handleChange}
                      style={{ height: "100px" }}
                      required
                    />
                  </Form.FloatingLabel>
                </Col>
              </Row>

              <Button className="mt-4" type="submit">
                Envoyer
              </Button>
            </Form>
            <div
              className={`fade alert mt-3 ${
                formResponse.responseOk ? "alert-success" : "alert-danger"
              } ${formResponse.message === "" ? "hide" : "show"}`}
            >
              {formResponse.message}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Contact;
