import { Container, Row, Col } from "react-bootstrap";

const croppedimageStyle = {
  width: "100%",
  height: "400px",
  backgroundImage: 'url("../images/handisport_mirepoix_superU.png")',
  backgroundPosition: "center bottom",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  borderRadius: "25% 75% / 50%",
  cursor: "normal",
  marginBottom: "30px",
  marginTop: "30px",
};

const Communication = () => {
  return (
    <section className="mb-3 mt-4 mb-lg-5" id="mission">
      <Container>
        <Row>
          <Col md={6} className="order-2 order-md-1">
            <h2
              className=" fw-normal lh-1"
              style={{
                letterSpacing: "-.05rem",
                fontSize: "40px",
              }}
            >
              Communication de l'association
            </h2>
            <p className="lead">
              <figure className="lead mt-4">
                <cite className="text-muted">
                  <q>
                    Quelques lignes sur notre projet : Après quelques mois
                    d'existence (depuis fin 2023) le basket club de mirepoix
                    ouvre sa section handisport.
                    <br />
                    <br />
                    Pour se faire le SUPER U DE MIREPOIX, soucieux du sujet de
                    l'inclusion par le sport a rendu ce projet possible en
                    signant un partenariat de 3 ans avec le financement de 6
                    fauteuils adaptés pour le basket.
                    <br />
                    <br />
                    Cette activité est ouverte en priorité aux personnes en
                    situation de handicap mais aussi aux personnes valides.
                    Cette activité sera pratiquée dans le cadre de loisirs et
                    pourquoi pas dans l'avenir en compétitions sous l'égide de
                    la Fédération Française Handisport.
                    <br />
                    <br />
                    L'activité de notre club se fait au gymnase de la cité
                    scolaire de Mirepoix.
                  </q>
                </cite>
              </figure>
            </p>
            <p className="fw-bolder lead text-end">Denis Brimaud</p>
          </Col>
          <Col md={6} style={{ margin: "auto" }} className="order-1 order-md-2">
            <div style={croppedimageStyle}></div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Communication;
